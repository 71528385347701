$r: ".l-body";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  flex-direction: column;
  color: $body-color;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
  font-family: "Montserrat", sans-serif;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
[class*="container"] {
  @include mixin-container;
}

.css-1h64wyx {
  display: none !important;
}