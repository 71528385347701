/* .c-description
-----------------------------------------------------------*/
$r: ".c-description";

// block
//-----------------------------------------------------------
#{$r} {
  line-height: 2;
  margin: 0 auto;
  text-align: center;
  max-width: $size * 134;
  
  @media (max-width: 767px) {
    font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    font-size: 16px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--color-white {
    color: $white;
  }
}

// influence
//-----------------------------------------------------------