@mixin mixin-container {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1279px) {
    padding-left: $size * 2;
    padding-right: $size * 2;
    max-width: $size * 160 + $size * 4;
  }

  @media (min-width: 1280px) {
    padding-left: $size * 4;
    padding-right: $size * 4;
    max-width: $size * 160 + $size * 8;
  }
}