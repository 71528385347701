$r: ".l-row";

// block
//-----------------------------------------------------------
#{$r} {
  
  & + &:not(&--vh-4) {
    margin-top: 8vh;
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--vh-4 {
    margin-top: 4vh;
  }
}

// influence
//-----------------------------------------------------------
