/* .c-anchor
-----------------------------------------------------------*/
$r: ".c-anchor";

// block
//-----------------------------------------------------------
#{$r} {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin-top: -$size * 11;

  // element
  //---------------------------------------------------------
  &__clients {
    margin-top: -$size * 14;

    @media (min-width: 960px) {
      margin-top: -$size * 22;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------