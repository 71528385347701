/* .c-modal
-----------------------------------------------------------*/
$r: ".c-modal";

// block
//-----------------------------------------------------------
#{$r} {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  animation-duration: 0.3s;
  animation-name: animationModal;
  display: none;

  @keyframes animationModal {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  // element
  //---------------------------------------------------------
  &__wrap {
    // display: grid;
    // place-items: center;
    // min-height: 100vh;
    // position: relative;

    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // overflow: auto;
    // position: absolute;
  }

  &__bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__content {
    z-index: 1;
    width: 100%;
    // height: 100%;
    margin: auto;
    padding: $size * 4;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    background-color: $white;
    transition: max-width 0.3s;

    @media (max-width: 1024px) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @media (min-width: 1025px) {
      top: 32px;
      left: 32px;
      right: 32px;
      bottom: 32px;
      max-width: $size * 150;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-name: animationModalContent;
      // border-radius: $border-radius;
    }
  }

  @keyframes animationModalContent {
    from {
      transform: scale(0.6);
    }

    to {
      transform: scale(1);
    }
  }

  &__close {
    top: $size;
    right: $size;
    cursor: pointer;
    position: fixed;
    transition: 0.6s;

    @media (max-width: 1279px) {
      width: $size * 2;
      height: $size * 2;
    }

    @media (min-width: 1280px) {
      width: $size * 4;
      height: $size * 4;
    }

    @media (min-width: 1280px) {
      position: absolute;
    }

    &:after,
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      content: "";
      margin: auto;
      position: absolute;
      background-color: $gray;
      transition: 0.3s;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:hover {
      &:after,
      &:before {
        background-color: $theme-color;
      }
    }
  }

  &__row {
    margin-top: $size * 2;

    @media (min-width: 1280px) {
      margin-top: $size * 4;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 18px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }

  &__text {
    line-height: 2;
    text-align: justify;
    margin-top: $size * 2;

    &:first-of-type {
      margin-top: 0;
    }
  }

  // modifier
  //---------------------------------------------------------
  &--center {
    overflow: hidden;

    #{$r}__content {
      top: 0;
      bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
    }
  }
}

// influence
//-----------------------------------------------------------
:checked + #{$r} {
  display: block;
}
