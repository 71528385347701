/* .c-future
-----------------------------------------------------------*/
$r: ".c-future";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  position: relative;

  @media (max-width: 960px) {
    align-items: center;
    flex-direction: column;
  }

  // element
  //---------------------------------------------------------
  &__content {
    z-index: 1;
    position: relative;

    @media (max-width: 567px) {
      padding-top: $size * 4;
    }

    @media (min-width: 568px) and (max-width: 959px) {
      padding-top: $size * 6;
      padding-left: $size * 4;
      padding-right: $size * 4;
    }

    @media (min-width: 960px) and (max-width: 1279px) {
      padding-left: $size * 4;
    }
  }
  
  &__row {

    @media (max-width: 767px) {
      margin-top: $size * 2;
    }
    
    @media (min-width: 768px) and (max-width: 1279px) {
      margin-top: $size * 3;
    }

    @media (min-width: 1280px) {
      margin-top: $size * 4;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__title {
    font-weight: 800;

    @media (max-width: 767px) {
      font-size: 20px;
    }

    @media (min-width: 768px) and (max-width: 1365px) {
      font-size: 34px;
    }

    @media (min-width: 1366px) {
      font-size: 40px;
      max-width: $size * 111;
    }
  }

  &__text {
    max-width: $size * 77;
    line-height: 2;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 22px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }

  &__img {

    @media (max-width: 767px) {
      max-height: $size * 22;
    }

    @media (max-width: 767px) and (min-height: 569px){
      max-height: $size * 26;
    }

    @media (max-width: 767px) and (min-height: 668px){
      max-height: $size * 34;
    }

    @media (min-width: 768px) and (max-width: 959px) {
      max-height: $size * 42;
    }

    @media (max-width: 959px) {
      order: -1;
    }

    @media (min-width: 960px) and (max-width: 1279px) {
      max-height: $size * 40;
    }
  }

  // modifier
  //---------------------------------------------------------
  &--orange {
    color: $orange;
  }
}

// influence
//-----------------------------------------------------------