// sizes
//-----------------------------------------------------------
$size: 8px;

// colors
//-----------------------------------------------------------
$white:        #ffffff;
$black:        #000000;
$gray:         #dadada;
$gray-dark:    #999999;
$gray-light:   #f1f4fd;
$purple:       #7183cf;
$orange:       #ff5e22;
$theme-color:  #3d67ac;
$body-color:   #040607;


// others
//-----------------------------------------------------------
$border-radius: 4px;