$r: ".l-header";

// block
//-----------------------------------------------------------
#{$r} {
  flex: none;
  top: 0;
  z-index: 9;
  height: $size * 11;
  position: sticky;
  background-color: $white;
  box-shadow: 0 0 8px rgba(0,0,0,.14);

  // element
  //---------------------------------------------------------
  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: $size * 2;
    padding-bottom: $size * 2;
    @include mixin-container;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    > * {
      
      @media (min-width: 768px) {
        margin-right: $size * 5;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
