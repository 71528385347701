/* .c-nav
-----------------------------------------------------------*/
$r: ".c-nav";

// block
//-----------------------------------------------------------
#{$r} {
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: 567px) {
    justify-content: space-between;
  }

  @media (min-width: 568px) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    order: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: $size + 4px;
    padding-bottom: 1px;
  }

  @media (min-width: 1280px) {
    justify-content: flex-end;
  }

  // element
  //---------------------------------------------------------
  &__item {
    color: inherit;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    transition: color 0.3s;

    @media (max-width: 567px) {
      margin-right: $size;
    }

    @media (min-width: 568px) and (max-width: 1279px) {
      margin-right: $size * 3;
    }

    @media (max-width: 959px) {
      font-size: 12px;
    }

    @media (min-width: 1280px) {
      margin-right: $size * 5;
    }

    &:last-child {
      margin-right: 0;
    }

    &:before {
      left: 0;
      right: 0;
      top: 100%;
      content: '';
      position: absolute;
      background-color: $orange;
      transform: scaleX(0);
      transition: transform 0.3s;

      @media (max-width: 969px) {
        height: $size / 8;
      }

      @media (min-width: 960px) {
        height: $size / 4;
      }
    }

    &:hover {

      @media (min-width: 1024px) {
        color: $orange;

        &:before {
          transform: scaleX(1);
        }
      }
    }

    &--active {
      color: $orange;

      &:before {
        transform: scaleX(1);
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------