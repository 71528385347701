/* .c-advantage
-----------------------------------------------------------*/
$r: ".c-advantage";

// block
//-----------------------------------------------------------
#{$r} {
  padding-top: 7vh;
  padding-bottom: 8vh;
  background-size: cover;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0i0KHQu9C+0LlfMSIgZGF0YS1uYW1lPSLQodC70L7QuSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTkyMCA1MDAiPjxkZWZzPjxzdHlsZT4uY2xzLTEsLmNscy00LC5jbHMtNSwuY2xzLTYsLmNscy03LC5jbHMtOHtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNmMmY1ZmU7fS5jbHMtM3tjbGlwLXBhdGg6dXJsKCNjbGlwLXBhdGgpO30uY2xzLTR7c3Ryb2tlOiNlYmYwZmI7fS5jbHMtNCwuY2xzLTUsLmNscy02LC5jbHMtNywuY2xzLTh7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjQwcHg7fS5jbHMtNXtzdHJva2U6I2VjZjFmYjt9LmNscy02e3N0cm9rZTojZWRmMWZjO30uY2xzLTd7c3Ryb2tlOiNlZWYyZmM7fS5jbHMtOHtzdHJva2U6I2VmZjNmYzt9PC9zdHlsZT48Y2xpcFBhdGggaWQ9ImNsaXAtcGF0aCI+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxLjY3IiB5PSItMC41MyIgd2lkdGg9IjE5MjAiIGhlaWdodD0iNTAwIi8+PC9jbGlwUGF0aD48L2RlZnM+PHRpdGxlPmJnXzM8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMiIgeD0iMS42NyIgeT0iLTAuNTMiIHdpZHRoPSIxOTIwIiBoZWlnaHQ9IjUwMCIvPjxnIGNsYXNzPSJjbHMtMyI+PGNpcmNsZSBjbGFzcz0iY2xzLTQiIGN4PSIwLjU0IiBjeT0iMjQ5LjQ3IiByPSI3MCIvPjxjaXJjbGUgY2xhc3M9ImNscy01IiBjeD0iMC41NCIgY3k9IjI0OS40NyIgcj0iMTcwIi8+PGNpcmNsZSBjbGFzcz0iY2xzLTYiIGN4PSIwLjU0IiBjeT0iMjQ5LjQ3IiByPSIyNzAiLz48Y2lyY2xlIGNsYXNzPSJjbHMtNyIgY3g9IjAuNTQiIGN5PSIyNDkuNDciIHI9IjM3MCIvPjxjaXJjbGUgY2xhc3M9ImNscy04IiBjeD0iMC41NCIgY3k9IjI0OS40NyIgcj0iNDcwIi8+PGNpcmNsZSBjbGFzcz0iY2xzLTgiIGN4PSIwLjU0IiBjeT0iMjQ5LjQ3IiByPSI1NzAiLz48Y2lyY2xlIGNsYXNzPSJjbHMtNCIgY3g9IjE5MjEuNjciIGN5PSIyNDkuNDciIHI9IjcwIi8+PGNpcmNsZSBjbGFzcz0iY2xzLTUiIGN4PSIxOTIxLjY3IiBjeT0iMjQ5LjQ3IiByPSIxNzAiLz48Y2lyY2xlIGNsYXNzPSJjbHMtNiIgY3g9IjE5MjEuNjciIGN5PSIyNDkuNDciIHI9IjI3MCIvPjxjaXJjbGUgY2xhc3M9ImNscy03IiBjeD0iMTkyMS42NyIgY3k9IjI0OS40NyIgcj0iMzcwIi8+PGNpcmNsZSBjbGFzcz0iY2xzLTgiIGN4PSIxOTIxLjY3IiBjeT0iMjQ5LjQ3IiByPSI0NzAiLz48Y2lyY2xlIGNsYXNzPSJjbHMtOCIgY3g9IjE5MjEuNjciIGN5PSIyNDkuNDciIHI9IjU3MCIvPjwvZz48L3N2Zz4=");
  
  // element
  //---------------------------------------------------------
  &__row {
    display: flex;
    justify-content: center;
    margin-top: 4vh;

    @media (max-width: 568px) {
      flex-direction: column;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__column {

    @media (max-width: 568px) {
      padding-top: $size;

      &:first-of-type {
        padding-top: 0;
      }
    }

    @media (max-width: 767px) {
      padding-right: $size * 4;
    }
  
    @media (min-width: 768px) {
      padding-right: $size * 10;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    
    @media (max-width: 767px) {
      margin-top: $size;
    }
  
    @media (min-width: 768px) {
      margin-top: $size * 3;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    
    @media (max-width: 767px) {
      height: $size * 5;
      max-width: $size * 5;
      min-width: $size * 5;
    }
  
    @media (min-width: 768px) {
      height: $size * 6;
      max-width: $size * 6;
      min-width: $size * 6;
      margin-right: $size;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------