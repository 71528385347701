// Montserrat
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat__400.woff") format("woff");
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat__500.woff") format("woff");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat__700.woff") format("woff");
}

@font-face {
  font-weight: 800;
  font-style: normal;
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat__800.woff") format("woff");
}