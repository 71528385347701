/* .c-logo
-----------------------------------------------------------*/
$r: ".c-logo";

// block
//-----------------------------------------------------------
#{$r} {
  display: inline-flex;

  // element
  //---------------------------------------------------------
  > * {
    width: 124.6753px;
    height: $size * 4;
    pointer-events: none;

    @media (min-width: 960px) {
      width: 218.1818px;
      height: $size * 7;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------