/* .c-brand
-----------------------------------------------------------*/
$r: ".c-brand";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  grid-row-gap: $size * 4;
  grid-column-gap: $size;
  grid-template-columns: repeat(3, 1fr);
  
  @media (min-width: 568px) {
    grid-template-columns: repeat(5, 1fr);
  }

  // @media (min-width: 960px) {
  //   padding-top: 8vh;
  // }

  // element
  //---------------------------------------------------------
  &__item {
    display: flex;
    align-items: center;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------