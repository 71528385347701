$r: ".l-main";

// block
//-----------------------------------------------------------
#{$r} {
  flex: 1 0 auto;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
