/* .c-process
-----------------------------------------------------------*/
$r: ".c-process";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  flex-direction: column;

  // element
  //---------------------------------------------------------
  &__row {
    display: flex;
    margin-top: $size * 8;

    @media (max-width: 567px) {
      flex-direction: column;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:nth-child(even) {

      @media (min-width: 568px) {
        flex-direction: row-reverse;
      }

      #{$r}__img {

        @media (min-width: 568px) {
          text-align: right;
        }
      }
    }
  }

  &__column {

    @media (min-width: 568px) {
      width: 50%;
    }

    &:first-child {
      display: flex;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: 700;
    position: relative;
    border-radius: 100%;
    background-color: $orange;

    @media (max-width: 767px) {
      height: $size * 3;
      min-width: $size * 3;
      font-size: 14px;
      transform: translateX(8px);
    }

    @media (min-width: 768px) {
      height: $size * 5;
      min-width: $size * 5;
      font-size: 18px;
      transform: translateX(16px);
    }

    &:after,
    &:before {
      content: '';
      margin: auto;
      position: absolute;
      border-radius: 100%;
    }

    &:after {

      @media (max-width: 767px) {
        top: -$size + 4px;
        left: -$size + 4px;
        right: -$size + 4px;
        bottom: -$size + 4px;
        border: $size / 8 solid lighten($orange, 24%);
      }

      @media (min-width: 768px) {
        top: -$size;
        left: -$size;
        right: -$size;
        bottom: -$size;
        border: $size / 4 solid lighten($orange, 24%);
      }
    }

    &:before {

      @media (max-width: 767px) {
        top: -$size;
        left: -$size;
        right: -$size;
        bottom: -$size;
        border: $size / 8 solid lighten($orange, 40%);
      }

      @media (min-width: 768px) {
        top: -$size * 2;
        left: -$size * 2;
        right: -$size * 2;
        bottom: -$size * 2;
        border: $size / 4 solid lighten($orange, 40%);
      }
    }
  }

  &__content {

    @media (max-width: 767px) {
      padding-left: $size * 4;
    }

    @media (min-width: 768px) {
      padding-left: $size * 7;
    }
  }

  &__title {
    max-width: $size * 60;
    font-size: 18px;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 18px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }

  &__text {
    line-height: 2;

    @media (max-width: 767px) {
      margin-top: $size;
    }

    @media (min-width: 768px) {
      margin-top: $size * 2;
    }
  }

  &__img {

    @media (max-width: 567px) {
      margin-top: $size * 2;
    }

    @media (min-width: 960px) {
      transform: translateY(-48px);

      &[src*="business-analysis"] {
        transform: translateY(-16px);
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------