$r: ".l-footer";

// block
//-----------------------------------------------------------
#{$r} {
  flex: none;
  background-color: $gray-light;

  @media (min-width: 768px) and (max-width: 1279px) {
    padding: $size * 2 0;
  }

  @media (min-width: 1280px) {
    padding: $size * 4 0;
  }

  // element
  //---------------------------------------------------------
  &__container {
    padding-top: $size * 2;
    padding-bottom: $size * 2;
    @include mixin-container;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-top: $size * 4;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &:first-of-type {
      margin-top: 0;

      @media (max-width: 1279px) {
        display: none;
      }
    }

    &:last-of-type {
      
      @media (max-width: 1279px) {
        margin-top: 0;
      }
    }

    > * {

      @media (min-width: 768px) {
        margin-right: $size * 5;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__copyright {
    font-size: 12px;

    @media (max-width: 767px) {
      color: $gray-dark;
    }
  }

  &__nav {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
      order: -1;
      margin-bottom: $size * 2;
    }

    &--item {
      font-size: 12px;
      color: inherit;
      cursor: pointer;
      margin-right: $size * 4;
      transition: color 0.3s;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        color: $theme-color;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
