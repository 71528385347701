/* .c-title
-----------------------------------------------------------*/
$r: ".c-title";

// block
//-----------------------------------------------------------
#{$r} {
  font-weight: 800;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 20px;
  }

  @media (min-width: 768px) and (max-width: 1365px) {
    font-size: 32px;
  }

  @media (min-width: 1366px) {
    font-size: 40px;
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--h3 {
    font-weight: 700;
    
    @media (max-width: 767px) {
      font-size: 18px;
    }

    @media (min-width: 768px) and (max-width: 1365px) {
      font-size: 28px;
    }

    @media (min-width: 1366px) {
      font-size: 32px;
    }
  }

  &--color-white {
    color: $white;
  }
}

// influence
//-----------------------------------------------------------