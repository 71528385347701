/* .c-button
-----------------------------------------------------------*/
$r: ".c-button";

// block
//-----------------------------------------------------------
#{$r} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 500;
  white-space: nowrap;
  border-radius: $border-radius;
  background-color: $orange;
  transition: 0.3s;

  @media (max-width: 767px) {
    height: $size * 4;
    font-size: 14px;
    padding-left: $size * 3;
    padding-right: $size * 3;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    height: $size * 5;
    font-size: 16px;
    padding-left: $size * 4;
    padding-right: $size * 4;
  }

  @media (min-width: 1280px) {
    height: $size * 6;
    font-size: 18px;
    padding-left: $size * 5;
    padding-right: $size * 5;
  }

  &:hover {
    background-color: darken($orange, 8%);
  }

  // element
  //---------------------------------------------------------
  &__inner {
    display: flex;
    align-items: center;
  }

  &__talk {

    @media (max-width: 959px) {
      font-size: 12px;
      height: $size * 4;
      padding-left: $size * 3;
      padding-right: $size * 3
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
