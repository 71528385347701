/* .c-contact
-----------------------------------------------------------*/
$r: ".c-contact";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  justify-content: space-around;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  // element
  //---------------------------------------------------------
  &__row {
    display: flex;
    align-items: baseline;

    @media (max-width: 767px) {
      margin-top: $size * 2;
    }

    @media (min-width: 768px) {
      margin-top: $size * 3;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    
    font-weight: 700;
    text-align: center;

    @media (max-width: 1279px) {
      font-size: 18px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }

  &__list {

    @media (max-width: 767px) {
      order: -1;
      margin-bottom: 8vh;
    }

    @media (min-width: 768px) {
      padding-right: $size * 4;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $orange;
    margin-right: $size * 2;
    border-radius: 100%;
    border: 1px solid $orange;
    transform: translateY(4px);

    @media (max-width: 767px) {
      height: $size * 4;
      min-width: $size * 4;
      font-size: 18px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      height: $size * 5;
      min-width: $size * 5;
      font-size: 22px;
    }

    @media (min-width: 1280px) {
      height: $size * 6;
      min-width: $size * 6;
      font-size: 24px;
    }
  }

  &__item {
    color: inherit;
    font-weight: 500;
    transition: color 0.3s;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 16px;
    }
    
    @media (min-width: 1280px) {
      font-size: 18px;
    }

    &[href] {
      cursor: pointer;
    }

    &:hover {
      color: $theme-color;
    }
  }

  &__form {
    width: 100%;
    border-radius: $border-radius;
    background-size: cover;
    background-position: 50% 50%;
    background-color: $gray-light;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4xLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2OTAgOTQwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2OTAgOTQwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cmVjdCB5PSIwIiBzdHlsZT0iZmlsbDojRjJGNUZFOyIgd2lkdGg9IjY5MCIgaGVpZ2h0PSI5NDAiLz4NCjxnPg0KCTxkZWZzPg0KCQk8cmVjdCBpZD0iU1ZHSURfMV8iIHg9IjAiIHdpZHRoPSI2OTAiIGhlaWdodD0iOTQwIi8+DQoJPC9kZWZzPg0KCTxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPg0KCQk8dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8xXyIgIHN0eWxlPSJvdmVyZmxvdzp2aXNpYmxlOyIvPg0KCTwvY2xpcFBhdGg+DQoJPGcgc3R5bGU9ImNsaXAtcGF0aDp1cmwoI1NWR0lEXzJfKTsiPg0KCQk8Y2lyY2xlIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNFQkYwRkI7c3Ryb2tlLXdpZHRoOjQwO3N0cm9rZS1taXRlcmxpbWl0OjEwOyIgY3g9IjM0My4zIiBjeT0iNDcwIiByPSI3MCIvPg0KCQk8Y2lyY2xlIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNFQ0YxRkI7c3Ryb2tlLXdpZHRoOjQwO3N0cm9rZS1taXRlcmxpbWl0OjEwOyIgY3g9IjM0My4zIiBjeT0iNDcwIiByPSIxNzAiLz4NCgkJPGNpcmNsZSBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojRURGMUZDO3N0cm9rZS13aWR0aDo0MDtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGN4PSIzNDMuMyIgY3k9IjQ3MCIgcj0iMjcwIi8+DQoJCTxjaXJjbGUgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6I0VFRjJGQztzdHJva2Utd2lkdGg6NDA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7IiBjeD0iMzQzLjMiIGN5PSI0NzAiIHI9IjM3MCIvPg0KCQk8Y2lyY2xlIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNFRkYzRkM7c3Ryb2tlLXdpZHRoOjQwO3N0cm9rZS1taXRlcmxpbWl0OjEwOyIgY3g9IjM0My4zIiBjeT0iNDcwIiByPSI0NzAiLz4NCgkJPGNpcmNsZSBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojRUZGM0ZDO3N0cm9rZS13aWR0aDo0MDtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGN4PSIzNDMuMyIgY3k9IjQ3MCIgcj0iNTcwIi8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=");
  
    @media (max-width: 767px) {
      padding: $size * 4 $size * 2;
    }
    
    @media (min-width: 768px) and (max-width: 1279px) {
      padding: $size * 3;
      max-width: $size * 54;
    }
    
    @media (min-width: 1280px) {
      max-width: $size * 66;
      padding: $size * 6;
    }
  }

  &__label {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:before {
      content: attr(data-title);
      margin-bottom: $size;
    }

    input,
    textarea {
      border-radius: $border-radius;
      background-color: $white;
      border: 1px solid $gray;
      transition: border-color 0.3s;

      @media (max-width: 767px) {
        height: $size * 5;
        padding-left: $size;
        padding-right: $size;
      }
  
      @media (min-width: 768px) {
        height: $size * 6;
        padding-left: $size * 2;
        padding-right: $size * 2;
      }

      &:focus {
        border-color: $theme-color;
      }
    }

    textarea {
      
      @media (max-width: 767px) {
        height: $size * 14;
        padding-top: $size;
        padding-bottom: $size;
      }
  
      @media (min-width: 768px) {
        height: $size * 18;
        padding-top: $size * 2;
        padding-bottom: $size * 2;
      }
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: $gray-light;
  }

  // modifier
  //---------------------------------------------------------
  &--center {
    justify-content: center;
  }
}

// influence
//-----------------------------------------------------------