/* .c-solution
-----------------------------------------------------------*/
$r: ".c-solution";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  grid-gap: $size * 2;

  @media (min-width: 568px) and (max-width: 959px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1280px) {
    grid-gap: $size * 4;
  }

  // element
  //---------------------------------------------------------
  &__block {
    padding: $size * 2; 
    background-color: $white;
    border-radius: $border-radius;

    @media (min-width: 1280px) {
      padding: $size * 4;
    }
  }

  &__title {
    font-weight: 700;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 18px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 20px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }

  &__list {

    @media (max-width: 767px) {
      margin-top: $size;
    }
    
    @media (min-width: 768px) {
      margin-top: $size * 2;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: $size;

    @media (min-width: 1280px) {
      margin-top: $size * 2;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    height: $size * 5;
    max-width: $size * 5;
    min-width: $size * 5;
    
    @media (min-width: 768px) {
      height: $size * 6;
      max-width: $size * 6;
      min-width: $size * 6;
    }
  }
  
  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------